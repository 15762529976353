<template>
    <v-container fluid>
        <v-row>
            <v-col class="text-center mt-15" sm="12" cols="12">
                {{$t('please_choose_entry_or_exit')}}
            </v-col>
            <v-col class="text-center" sm="12" cols="12">
                <v-card slot="footer"  class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="fill-height py-10 justify-center"  @click="getSecurity('security.entry')">
                        <v-icon>mdi-truck-plus-outline mdi-48px</v-icon>
                            <v-card-subtitle>{{$t('entry')}}</v-card-subtitle>
                    </v-container>
                </v-card>
                <v-card slot="footer"  class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"
                >
                    <v-container class="fill-height py-10 justify-center"  @click="getSecurity('security.exit')">
                        <v-icon>mdi-truck-minus-outline mdi-48px</v-icon>
                        <br />
                        <div><v-card-subtitle style="display: block">{{$t('exit')}}</v-card-subtitle></div>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-overlay :value="loading" z-index="10" color="primary">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>


export default {
    name: "Security",

    data() {
        return {

            options: {},
            loading: false,
            securities: [],

        };
    },


    methods: {
        getSecurity(rout) {
            this.$router.push({
                name: rout,
            })
        },
    }
}
</script>
